import { db } from '../../../firebase_config/firebase_config'
import { query, collection, where, getDocs } from 'firebase/firestore'
import { UserData } from '../../../types'
import { getContentById } from '../utils'
import { constructUsersRoute } from '../../axios/routes'
import { post, put, get } from '../../axios/request'

export const addUser = async (user: UserData) => {
    const url = constructUsersRoute()
    const res = await post<{ userId: string }>(url, { user })
    return res.userId
}

export const updateUser = async (uid: string, newUser) => {
    const url = constructUsersRoute(uid)
    await put(url, { newUser })
}

export const deleteUser = async () => {}

export const getUser = async (uid) => {
    const url = constructUsersRoute(uid)
    const res = await get<UserData>(url)
    return res
}

export const getViscapUsers = async () => {
    const q = query(collection(db, 'users'), where('company', '==', 'Viscap Media'))
    let arr = []
    try {
        const res = await getDocs(q)
        res.docs.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }))
        return arr
    } catch (error) {
        throw new Error(error)
    }
}

export const getUserByEmail = async (email: string): Promise<UserData> => {
    const url = constructUsersRoute()
    const res = await post<UserData>(`${url}/email`, { email })
    return res
}

export const userWithEmailExists = async (email: string) => {
    const url = constructUsersRoute()
    const res = await post<UserData>(`${url}/exists`, { email })
    return res
}

export const getUsersByTeam = async (teamId: string): Promise<UserData[]> => {
    const url = constructUsersRoute()
    const res = await get<UserData[]>(`${url}/team/${teamId}`)
    return res
}

export const getUsernamesByIds = async (ids: string[]) => {
    try {
        const res = await getContentById(ids, 'users')
        return res.map((u) => ({ id: u.uid, name: u.displayName }))
    } catch (error) {
        throw new Error(error)
    }
}

export const userAcceptTeams = async (user, teamIds: string[]) => {
    const url = constructUsersRoute()
    await post(`${url}/accept-teams`, { user, teamIds })
}

export const getUserSubscriptions = async (userId) => {
    try {
        const q = query(collection(db, 'users', userId, 'subscriptions'))
        const userSubscriptions = await getDocs(q)
        return userSubscriptions.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    } catch (error) {
        throw new Error(error)
    }
}

export const restoreUser = async (uid) => {
    const url = constructUsersRoute(uid)
    await put(`${url}/restore`)
}
