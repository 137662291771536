import { constructBrandTagsRoute } from '../../axios/routes'
import { get, put } from '../../axios/request'

export interface BrandTags {
    clips: string[]
    elements: string[]
    deliverables: string[]
    shootingSessions: string[]
}

export interface BrandTagsResponse {
    brandTags: BrandTags
}

export const setBrandTagsFirestore = async (brandId: string, brandTags: BrandTags) => {
    const url = constructBrandTagsRoute(brandId)
    await put<{ updatedDoc: BrandTags }>(url, { brandTags })
}

export const getBrandTags = async (brandId: string) => {
    const url = constructBrandTagsRoute(brandId)
    const res: BrandTagsResponse = await get<BrandTagsResponse>(url)
    return res.brandTags
}
