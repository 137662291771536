import { constructBrandLocationsRoute } from '../../axios/routes'
import { get, put } from '../../axios/request'
import { BrandLocations } from '@viscapmedia/viscap-schema-types'

export const setBrandLocationsFirestore = async (brandId: string, locations: string[]) => {
    try {
        const url = constructBrandLocationsRoute(brandId)
        await put<{ updatedDoc: BrandLocations }>(url, { locations })
    } catch (error) {
        throw new Error(error)
    }
}

export const getBrandLocations = async (brandId: string) => {
    const url = constructBrandLocationsRoute(brandId)
    const res: BrandLocations = await get<BrandLocations>(url)
    return res.locations
}
