import { constructTemplatesRoute } from '../../axios/routes'
import { TeamsTemplates } from '@viscapmedia/viscap-schema-types'
import { del, get, post, put } from '../../axios/request'

export const getTeamTemplates = async (teamId) => {
    const url = constructTemplatesRoute(teamId)
    const res: TeamsTemplates[] = await get<TeamsTemplates[]>(url)
    return res
}

export const addTeamTemplate = async (teamId, template) => {
    const url = constructTemplatesRoute(teamId)
    const res = await post(url, template)
    return res
}

export const deleteTemplate = async (teamId, templateId) => {
    const url = constructTemplatesRoute(teamId)
    await del(`${url}/${templateId}`)
}

export const updateTemplate = async (teamId, template) => {
    const url = constructTemplatesRoute(teamId)
    await put<{ updatedDoc: TeamsTemplates }>(url, { template })
}
