import { TeamLibraryTalentData } from '../../../types'
import {
    collection,
    doc,
    setDoc,
} from 'firebase/firestore'
import { db, functions } from '../../../firebase_config/firebase_config'
import { AuditionFormValues } from '../../../components/Admin/Talent/AuditionForm/types'
import { UploadFile } from 'antd'
import { isEqual, omit, pick, uniqWith } from 'lodash'
import { parseTalentLocation, parseTalentSmartphoneModel } from '../../../components/Admin/Talent/AuditionForm/data'
import { compressImage, getFilenameTimestamp } from '../utils'
import { uploadFileFromUrl, uploadMediaFile } from '../storage'
import { httpsCallable } from 'firebase/functions'
import { get, patch, post } from '../../axios/request'
import { TalentMedia, Talent, TalentSuggestionsData} from '@viscapmedia/viscap-schema-types'
import { constructTalentSuggestionRoute, constructTalentRoute, constructTalentLibraryRoute } from '../../axios/routes'

export const getTalent = async (talentId?: string) => {
    try {
        const url = constructTalentRoute(talentId)
        const res = await get<Talent>(url)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const findTalentId = async (email: string) => {
    const url = constructTalentRoute()
    const res = await post<Talent>(`${url}/get-by-email`, { email })
    return res?.id
}

export const createTalent = async (
    talentData: Omit<Talent, 'id'>
): Promise<{
    success: boolean
    talentData: Talent
}> => {
    const url = constructTalentRoute()
    const res = await post<{ message: string; talent: Talent }>(url, talentData)
    return {
        success: true,
        talentData: res.talent,
    }
}

export const updateTalent = async (talentData: Partial<Talent>) => {
    const url = constructTalentRoute(talentData.id)
    await patch(url, talentData)
}

export const getTalentByEmail = async (email: string) => {
    try {
        const url = constructTalentRoute()
        const res = await post<Talent>(`${url}/get-by-email`, { email })
        return res
    } catch (error) {
        console.log(error)
    }
}

export const updateTeamTalent = async (talent: Partial<TeamLibraryTalentData>, talentId: string, team: string) => {
    try {
        const url = constructTalentLibraryRoute(team, talentId)
        await patch<{ message: string; updatedDoc: TeamLibraryTalentData }>(url, talent)
    } catch (error) {
        console.log(error)
    }
}

export const tagTeamLibraryTalent = async (teamId: string, talentId: string, tags: string[]) => {
    try {
        const url = constructTalentLibraryRoute(teamId, talentId)
        await patch<{ message: string; updatedDoc: TeamLibraryTalentData }>(url, { tags })
    } catch (error) {
        console.log(error)
    }
}

export const addBrandTalent = async (brandId: string, talent: TalentSuggestionsData ) => {
    const url = constructTalentSuggestionRoute({brandId})
    const res = await post<{ talentId: string }>(url, talent)
    return res.talentId
}

export const getBrandTalent = async (brandId) => {
        const url = constructTalentSuggestionRoute({brandId});
        const res = await get<TalentSuggestionsData[]>(url)
        return res
}

export const sendTalentApplication = async (teamId, talentData) => {
    const libData: TeamLibraryTalentData = {
        id: talentData.id,
        appliedAt: new Date(),
        status: 'pending',
        comments: [],
        tags: [],
        previouslyUsed: false,
        profile: talentData,
    }

    const ref = collection(db, `teams`, teamId, `talentLibrary`)
    try {
        await setDoc(doc(ref, talentData.id), libData)
    } catch (error) {
        console.log(error)
    }
}

export const getTalentBrandEntries = async (talentId: string) => {
    const url = constructTalentSuggestionRoute({talentId});
    const res = await get<TalentSuggestionsData[]>(url)
    return res
}

export const updateBrandTalent = async ( brandId: string, talentId: string, updatedFields: any) => {
    const url = constructTalentSuggestionRoute({brandId, talentId})
    await patch(url, updatedFields)
}

export const getBrandTalentById = async (brandId: string, talentId: string) => {
        const url = constructTalentSuggestionRoute({brandId, talentId});
        const res = await get<TalentSuggestionsData[]>(url)
        return res
}

export const changeStatusTeamLibraryTalent = async (teamId: string, talentId: string, status: string) => {
    try {
        const url = constructTalentLibraryRoute(teamId, talentId)
        await patch<{ message: string; updatedDoc: TeamLibraryTalentData }>(url, { status })
    } catch (error) {
        console.log(error)
    }
}

export const getTeamTalentLibrary = async (teamId: string) => {
    const url = constructTalentLibraryRoute(teamId)
    const res = get<TeamLibraryTalentData[]>(url)
    return res
}

export const getTeamTalentLibrarySize = async (teamId: string) => {
    const url = constructTalentLibraryRoute(teamId)
    const countUrl = `${url}/get-count`
    return await post<number>(countUrl)
}

export const checkTalentLibraryItem = async (teamId: string, email: string) => {
    const url = constructTalentLibraryRoute(teamId)
    const checkUrl = `${url}/talent-exists`
    return await post<boolean>(checkUrl, { email })
}

export const uploadTalentMedia = async ({
    file,
    type,
    talentEmail,
    talentId,
    teamId,
}: {
    file: File
    type: 'photo' | 'video'
    talentEmail?: string
    talentId?: string
    teamId?: string
}): Promise<TalentMedia> => {
    if ((!talentEmail && !teamId) || (teamId && !talentId)) {
        console.warn('uploadTalentMedia: wrong config')
        return
    }

    const compressedFile = type === 'photo' ? await compressImage(file) : file

    const filename = getFilenameTimestamp(file.name)

    const folderPath = teamId ? `/team/${teamId}/talentLibrary/${talentId}` : `/talent/${talentEmail}`
    const storagePath = `${folderPath}/${filename}`

    const url = await uploadMediaFile({
        storagePath,
        file: compressedFile,
        fileType: type,
    })

    const bucketPath = storagePath.slice(1)
    let thumbnailURL = ''
    if (type === 'video') {
        const makeThumbnail = httpsCallable<{ bucketPath: string }, string>(functions, 'makeVideoThumbnail')
        const res = await makeThumbnail({ bucketPath })
        thumbnailURL = res.data
    }

    return {
        url,
        filename,
        ...(type === 'video' ? { bucketPath, thumbnailURL } : {}),
    }
}

export const getTalentDataFromValues = ({
    values,
}: {
    values: AuditionFormValues
    reviewedByUser?: boolean
}): Omit<Talent, 'id' | 'createdAt' | 'reviewedByUser'> => {
    return {
        name: `${values.info.firstName} ${values.info.lastName}`,
        email: values.info.email,
        headshot: values.photos.headshot as TalentMedia,
        info: {
            ...pick(values.info, [
                'portfolioLink',
                'phoneNumber',
                'preferredContactMethod',
                'dateOfBirth',
                'remoteWorkStatus',
            ]),
            location: parseTalentLocation(values?.info?.location),
            socialMediaProfiles: values.info.socialMediaProfiles.map((p) => p.url).filter(Boolean),
        },
        measurements: {
            ...values.measurements,
            height: `${values.measurements.height.feet}'${values.measurements.height.inch || 0}"`,
        },
        appearance: { ...values.appearance },
        equipment: {
            ...omit(values.equipmentAccess, ['smartphoneBrand', 'smartphoneModel']),
            smartphoneModel: parseTalentSmartphoneModel(
                values.equipmentAccess.smartphoneBrand,
                values.equipmentAccess.smartphoneModel
            ),
        },
        vocalInfo: {
            primaryLanguage: values.vocalInfo.primaryLanguage,
            additionalLanguages: values.vocalInfo.additionalLanguages,
            accents: values.vocalInfo.accents,
        },
        media: {
            talentPhotos: values.photos.extra as TalentMedia[],
            screenTest: values.screenTest as TalentMedia,
            additionalVideos: values.miscInfo.extraVideos as TalentMedia[],
        },
        miscInfo: {
            ...omit(values.miscInfo, ['extraVideos']),
            rentalLocationPhotos: values.miscInfo.rentalLocationPhotos as TalentMedia[],
            industryPreferences: values.miscInfo.industryPreferences,
        },
    }
}

export const createUpdateTalentFromFormValues = async ({
    values,
    talentData,
    reviewedByUser,
}: {
    values: AuditionFormValues
    talentData?: Talent
    reviewedByUser?: boolean
}): Promise<{
    talentData: Talent
}> => {
    let uploadedHeadshot = values.photos.headshot
    let uploadedTalentPhotos = values.photos.extra
    let uploadedRentalPhotos = values.miscInfo.rentalLocationPhotos
    let uploadedScreenTest = values.screenTest
    let uploadedExtraVideos = values.miscInfo.extraVideos

    try {
        if (values.photos.headshot && !values.photos.headshot?.url) {
            uploadedHeadshot = await uploadTalentMedia({
                file: (values.photos.headshot as UploadFile).originFileObj,
                type: 'photo',
                talentEmail: values.info.email,
            })
        }

        uploadedTalentPhotos = await Promise.all(
            values.photos.extra.map(async (photo) => {
                if (photo.url) return photo

                return await uploadTalentMedia({
                    file: (photo as UploadFile).originFileObj,
                    type: 'photo',
                    talentEmail: values.info.email,
                })
            })
        )

        uploadedRentalPhotos = await Promise.all(
            values.miscInfo.rentalLocationPhotos.map(async (photo) => {
                if (photo.url) return photo

                return await uploadTalentMedia({
                    file: (photo as UploadFile).originFileObj,
                    type: 'photo',
                    talentEmail: values.info.email,
                })
            })
        )

        if (values.screenTest && !values.screenTest?.url) {
            uploadedScreenTest = await uploadTalentMedia({
                file: (values.screenTest as UploadFile).originFileObj,
                type: 'video',
                talentEmail: values.info.email,
            })
        }

        uploadedExtraVideos = await Promise.all(
            values.miscInfo.extraVideos.map(async (video) => {
                if (video.url) return video

                return await uploadTalentMedia({
                    file: (video as UploadFile).originFileObj,
                    type: 'video',
                    talentEmail: values.info.email,
                })
            })
        )
    } catch (err) {
        console.log(err)
        return
    }

    console.log('Media loaded')

    const talentDataFromValues = getTalentDataFromValues({
        values: {
            ...values,
            photos: {
                ...values.photos,
                headshot: uploadedHeadshot as TalentMedia,
                extra: uploadedTalentPhotos as TalentMedia[],
            },
            screenTest: uploadedScreenTest as TalentMedia,
            miscInfo: {
                ...values.miscInfo,
                rentalLocationPhotos: uploadedRentalPhotos as TalentMedia[],
                extraVideos: uploadedExtraVideos as TalentMedia[],
            },
        },
        reviewedByUser,
    })

    const updatedTalentData = talentData
        ? ({
              ...talentData,
              ...talentDataFromValues,
              reviewedByUser: typeof reviewedByUser === 'boolean' ? reviewedByUser : talentData.reviewedByUser,
          } as Talent)
        : ({
              ...talentDataFromValues,
              createdAt: new Date(),
              reviewedByUser: !!reviewedByUser,
          } as Omit<Talent, 'id'>)

    try {
        if (talentData) {
            await updateTalent(updatedTalentData as Talent)

            return {
                talentData: updatedTalentData as Talent,
            }
        } else {
            const result = await createTalent(updatedTalentData as Omit<Talent, 'id'>)

            return {
                talentData: result.talentData,
            }
        }
    } catch (err) {
        return
    }
}

export const getTeamTalentDataFromFormValues = async ({
    values,
    teamId,
    talentData,
}: {
    values: AuditionFormValues
    teamId: string
    talentData: Talent
}): Promise<Talent> => {
    let uploadedHeadshot = values.photos.headshot
    let uploadedTalentPhotos = values.photos.extra
    let uploadedRentalPhotos = values.miscInfo.rentalLocationPhotos
    let uploadedScreenTest = values.screenTest
    let uploadedExtraVideos = values.miscInfo.extraVideos

    try {
        if (values.photos.headshot) {
            if (values.photos.headshot.url && values.photos.headshot.url.includes(teamId)) {
                uploadedHeadshot = { ...values.photos.headshot }
            } else if (values.photos.headshot.url) {
                const filename = (values.photos.headshot as TalentMedia).filename || 'headshot'

                const url = await uploadFileFromUrl(
                    values.photos.headshot.url,
                    `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                )

                uploadedHeadshot = {
                    url,
                    filename,
                }
            } else {
                uploadedHeadshot = await uploadTalentMedia({
                    file: (values.photos.headshot as UploadFile).originFileObj,
                    type: 'photo',
                    talentId: talentData.id,
                    teamId,
                })
            }
        }

        uploadedTalentPhotos = await Promise.all(
            values.photos.extra.map(async (photo, i) => {
                if (photo.url) {
                    if (photo.url.includes(teamId)) {
                        return { ...photo }
                    }

                    const filename = (photo as TalentMedia).filename || `extra${i}`

                    const url = await uploadFileFromUrl(
                        photo.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                    )

                    return {
                        url,
                        filename,
                    }
                } else {
                    return await uploadTalentMedia({
                        file: (photo as UploadFile).originFileObj,
                        type: 'photo',
                        talentId: talentData.id,
                        teamId,
                    })
                }
            })
        )

        uploadedRentalPhotos = await Promise.all(
            values.miscInfo.rentalLocationPhotos.map(async (photo, i) => {
                if (photo.url) {
                    if (photo.url.includes(teamId)) {
                        return { ...photo }
                    }

                    const filename = (photo as TalentMedia).filename || `rental${i}`

                    const url = await uploadFileFromUrl(
                        photo.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                    )

                    return {
                        url,
                        filename,
                    }
                } else {
                    return await uploadTalentMedia({
                        file: (photo as UploadFile).originFileObj,
                        type: 'photo',
                        talentId: talentData.id,
                        teamId,
                    })
                }
            })
        )

        if (values.screenTest) {
            if (values.screenTest.url && values.screenTest.url.includes(teamId)) {
                uploadedScreenTest = { ...values.screenTest }
            } else if (values.screenTest.url) {
                const filename = (values.screenTest as TalentMedia).filename || 'screenTest'

                const bucketPath = `team/${teamId}/talentLibrary/${talentData.id}/${filename}`

                const url = await uploadFileFromUrl(values.screenTest.url, bucketPath)

                uploadedScreenTest = {
                    url,
                    filename,
                    bucketPath,
                }
            } else if (!!(values.screenTest as UploadFile)?.originFileObj) {
                uploadedScreenTest = await uploadTalentMedia({
                    file: (values.screenTest as UploadFile).originFileObj,
                    type: 'video',
                    talentId: talentData.id,
                    teamId,
                })
            }
        }

        uploadedExtraVideos = await Promise.all(
            values.miscInfo.extraVideos.map(async (video, i) => {
                if (video.url) {
                    if (video.url.includes(teamId)) {
                        return {
                            url: video.url,
                            filename: video.filename,
                            bucketPath: video.bucketPath,
                            thumbnailURL: video.thumbnailURL
                        }
                    }

                    const filename = (video as TalentMedia).filename || `extraVideo${i}`
                    const bucketPath = `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                    const bucketPathThumb = `team/${teamId}/talentLibrary/${talentData.id}/${filename}.thumb.jpg`

                    const url = await uploadFileFromUrl(video.url, bucketPath)
                    let thumbnailURL = ''

                    if (video.thumbnailURL) {
                        thumbnailURL = await uploadFileFromUrl(video.thumbnailURL, bucketPathThumb)
                    } else {
                        const makeThumbnail = httpsCallable<{ bucketPath: string }, string>(
                            functions,
                            'makeVideoThumbnail'
                        )

                        try {
                            const res = await makeThumbnail({ bucketPath })
                            thumbnailURL = res.data
                        } catch (err) {
                            console.error(err)
                        }
                    }

                    return {
                        url,
                        filename,
                        bucketPath,
                        thumbnailURL,
                    }
                } else if (!!(video as UploadFile)?.originFileObj) {
                    return await uploadTalentMedia({
                        file: (video as UploadFile).originFileObj,
                        type: 'video',
                        talentId: talentData.id,
                        teamId,
                    })
                } else {
                    return video
                }
            })
        )
    } catch (err) {
        console.log(err)
        return null
    }

    console.log('Media loaded')

    return {
        ...talentData,
        ...getTalentDataFromValues({
            values: {
                ...values,
                photos: {
                    ...values.photos,
                    headshot: uploadedHeadshot as TalentMedia,
                    extra: uploadedTalentPhotos as TalentMedia[],
                },
                screenTest: uploadedScreenTest as TalentMedia,
                miscInfo: {
                    ...values.miscInfo,
                    rentalLocationPhotos: uploadedRentalPhotos as TalentMedia[],
                    extraVideos: uploadedExtraVideos as TalentMedia[],
                },
            },
        }),
        createdAt: talentData?.createdAt || new Date(),
        teams: uniqWith(
            [
                ...(talentData?.teams || []),
                {
                    id: teamId,
                },
            ],
            isEqual
        ),
    }
}

export const getTeamTalentDataFromData = async ({
    talentData,
    teamId,
}: {
    talentData: Talent
    teamId: string
}): Promise<Talent> => {
    try {
        const headshotUrl =
            talentData.headshot?.url &&
            (await uploadFileFromUrl(
                talentData.headshot.url as string,
                `team/${teamId}/talentLibrary/${talentData.id}/${talentData.headshot.filename || 'headshot'}`
            ))

        const talentPhotosUrls = await Promise.all(
            (talentData.media?.talentPhotos || []).map(async (item: any, i: number) => {
                return (
                    item?.url &&
                    (await uploadFileFromUrl(
                        item.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${item.filename || `talentPhoto${i}`}`
                    ))
                )
            })
        )

        const screenTestUrl =
            (talentData.media?.screenTest &&
                (await uploadFileFromUrl(
                    (talentData.media.screenTest as TalentMedia)?.url || talentData.media.screenTest as string,
                    `team/${teamId}/talentLibrary/${talentData.id}/${
                        (talentData.media.screenTest as TalentMedia).filename || 'screenTest'
                    }`
                )))

        const rentalPhotosUrls = await Promise.all(
            (talentData.miscInfo?.rentalLocationPhotos || []).map(async (item: any, i: number) => {
                return (
                    item?.url &&
                    (await uploadFileFromUrl(
                        item.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${item.filename || `rentalPhoto${i}`}`
                    ))
                )
            })
        )

        const extraVideoUrls = await Promise.all(
            (talentData.media?.additionalVideos || []).map(async (item: any, i: number) => {
                return (
                    item?.url &&
                    (await uploadFileFromUrl(
                        item.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${item.filename || `rentalPhoto${i}`}`
                    ))
                )
            })
        )

        return {
            ...talentData,
            headshot: talentData.headshot && {
                ...talentData.headshot,
                url: headshotUrl,
            },
            media: {
                ...talentData.media,
                talentPhotos: talentData.media?.talentPhotos?.map((item: any, i: number) => ({
                    ...item,
                    url: talentPhotosUrls[i],
                })),
                screenTest: talentData.media?.screenTest && typeof talentData.media?.screenTest == 'object' ?{
                    ...(talentData.media.screenTest as TalentMedia),
                    url: screenTestUrl,
                } : {url: screenTestUrl},
                additionalVideos: talentData.media?.additionalVideos?.map((item: any, i: number) => ({
                    ...item,
                    url: extraVideoUrls[i],
                })),
            },
            miscInfo: {
                ...talentData.miscInfo,
                rentalLocationPhotos: talentData.miscInfo?.rentalLocationPhotos?.map((item: any, i: number) => ({
                    ...item,
                    url: rentalPhotosUrls[i],
                })),
            },
            teams: uniqWith(
                [
                    ...(talentData?.teams || []),
                    {
                        id: teamId,
                    },
                ],
                isEqual
            ),
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const createUpdateTeamTalent = async ({
    talentData,
    teamId,
    status,
    formValues,
}: {
    talentData: Talent
    teamId: string
    status: 'pending' | 'approved'
    formValues?: AuditionFormValues
}) => {
    try {
        const teamTalentData = formValues
            ? await getTeamTalentDataFromFormValues({
                  values: formValues,
                  talentData: talentData,
                  teamId,
              })
            : await getTeamTalentDataFromData({
                  talentData: talentData,
                  teamId,
              })
        if (!teamTalentData) {
            return {
                success: false,
            }
        }

        const application = {
            id: talentData.id,
            appliedAt: new Date(),
            status,
            comments: [],
            tags: [],
            previouslyUsed: false,
            profile: teamTalentData,
        }
        const url = constructTalentLibraryRoute(teamId)
        await post<{ message: string; talentId: string }>(url, application)

        return {
            success: true,
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}
