import { get, post } from '../../axios/request'
import { BrandProps } from '@viscapmedia/viscap-schema-types'
import { constructBrandPropsRoute } from '../../axios/routes'

export const setBrandPropsFirestore = async (brandId: string, props: string[]) => {
    const url = constructBrandPropsRoute(brandId)
    await post<BrandProps[]>(url, { props })
}

export const getBrandProps = async (brandId: string) => {
    const url = constructBrandPropsRoute(brandId)
    const res: string[] = await get<string[]>(url)
    return res
}
